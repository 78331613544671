/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
import type { ParamJSONData } from '@kiroboio/fct-core';
import { get as lodashGet } from 'lodash';

import { isGroupMarkerId } from '../../Nodes/utils/createNode';
import { isParameterPath } from '../utils';

import { UILabels } from '@kiroboio/fct-builder';
import type { StoreSlice } from '.';

type NodeId = string;
type Path = string;

type PointersMap = Record<
  NodeId,
  Record<
    Path,
    {
      nodeId: string;
      path: string;
    }
  >
>;

export type ParameterValue = ParamJSONData['value'];
type Output = Extract<ParameterValue, { type: 'output' }>;

export const isOutput = (value: any): value is Output => {
  return Boolean(
    value &&
      typeof value === 'object' &&
      'type' in value &&
      value.type === 'output'
  );
};

type DepResult = {
  value?: ParameterValue;
  decimals?: string;
  ioType?: ParamJSONData['ioType'];
  depIoType?: ParamJSONData['ioType'];
  token?: {
    value?: ParameterValue;
    decimals?: string;
  };
};

export const getValueKey = (nodeId: string, path: string) =>
  `${nodeId}.${path}`;
export type PointersSlice = {
  pointersSlice: {
    pointers: PointersMap;
    inputMarkers: PointersMap;
    clearValues: (nodeId: string) => void;
    clearAllValues: () => void;
    isInputMarked: (nodeId: string, path: string) => boolean;
    createMarkers: () => void;
    createPointers: () => void;
    isPointed: (nodeId: string, path: string) => boolean;
    parameterDetails: Map<string, Map<string, DepResult>>;

    getValue: (nodeId: string, path: string) => ParameterValue;
    setIntentValue: (
      nodeId: string,
      path: string,
      value: ParameterValue | undefined,
      handleType: 'INPUT' | 'OUTPUT',
      parseString?: boolean
    ) => void;
    setValue: (
      nodeId: string,
      path: string,
      value: ParameterValue | undefined,
      parseString?: boolean
    ) => void;
    value: Map<string, Map<string, ParameterValue>>;
    decimals: Map<string, Map<string, string | undefined>>;
    setDecimals: (
      nodeId: string,
      path: string,
      handleType: 'INPUT' | 'OUTPUT',
      decimals: string
    ) => void;
    setSymbol: (
      nodeId: string,
      path: string,
      handleType: 'INPUT' | 'OUTPUT',
      symbol: string | undefined
    ) => void;
    getPointedNodeId: (nodeId: string, path: string, depth?: number) => string;
    getPointer: (nodeId: string, path: string) => PointersMap[NodeId][Path];
    isPointedFromOutput: (nodeId: string, path: string) => boolean;
  };
};

export const createPointersSlice: StoreSlice<PointersSlice> = (set, get) => ({
  pointersSlice: {
    value: new Map(),
    decimals: new Map(),
    parameterDetails: new Map(),
    pointers: {},
    inputMarkers: {},
    createPointers: () => {
      set({
        pointersSlice: {
          ...get().pointersSlice,
          pointers: get()
            .edges.filter(
              (e) =>
                isParameterPath(e.sourceHandle) &&
                isParameterPath(e.targetHandle)
            )
            .reduce((acc, e) => {
              if (!acc[e.target]) acc[e.target] = {};
              acc[e.target][e.targetHandle as string] = {
                nodeId: e.source,
                path: e.sourceHandle as string,
              };
              return acc;
            }, {} as PointersMap),
        },
      });
    },
    createMarkers: () => {
      set({
        pointersSlice: {
          ...get().pointersSlice,
          inputMarkers: get()
            .edges.filter(
              (e) =>
                isGroupMarkerId(e.source) && isParameterPath(e.targetHandle)
            )
            .reduce((acc, e) => {
              if (!acc[e.target]) acc[e.target] = {};
              acc[e.target][e.targetHandle as string] = {
                nodeId: e.source,
                path: e.sourceHandle as string,
              };
              return acc;
            }, {} as PointersMap),
        },
      });
    },

    isPointedFromOutput: (nodeId, path) => {
      const { intentGraph } = get();

      if (!intentGraph) return false;
      const isPointed = intentGraph.getParam({
        id: nodeId,
        handle: path,
        handleType: 'INPUT',
      })?.value.jsonParam?.isPointed;

      return Boolean(isPointed);
    },

    isInputMarked: (nodeId, path) => {
      const groupId = get().getParentGroupIdForNode(nodeId);
      if (!groupId) return false;

      const isCurrentlyInSelectedGroup = get().selectedGroup === groupId;
      return (
        isCurrentlyInSelectedGroup &&
        Boolean(lodashGet(get().pointersSlice.inputMarkers, [nodeId, path]))
      );
    },

    getPointer: (nodeId, path) => {
      const nodePointers = get().pointersSlice.pointers[nodeId];
      if (!nodePointers || !nodePointers[path]) return { nodeId, path };
      return get().pointersSlice.getPointer(
        nodePointers[path].nodeId,
        nodePointers[path].path
      );
    },

    isPointed: (nodeId, path) =>
      Boolean(lodashGet(get().pointersSlice.pointers, [nodeId, path])),

    getValue: (nodeId, path) => {
      const param = get().intentGraph?.getParam({
        id: nodeId,
        handle: path,
        handleType: path?.includes(UILabels.OUTPUT) ? 'OUTPUT' : 'INPUT',
      });

      return param?.value.param.value as ParameterValue;
    },
    setIntentValue: (nodeId, path, paramVal, handleType, parseString) => {
      get().intentGraph?.setParamValue({
        id: nodeId,
        handle: path,
        handleType,
        value: paramVal || undefined,
        parseString,
      });
    },

    setValue: (nodeId, path, paramVal, parseString) => {
      get().intentGraph?.setParamValue({
        id: nodeId,
        handle: path,
        handleType: 'INPUT',
        value: paramVal || undefined,
        parseString,
      });
    },

    setDecimals: (nodeId, path, handleType, decimals) => {
      get().intentGraph?.setParamDecimals({
        id: nodeId,
        handle: path,
        handleType,
        decimals: Number(decimals),
      });
    },

    setSymbol: (nodeId, path, handleType, symbol) => {
      get().intentGraph?.setParamMeta({
        id: nodeId,
        handle: path,
        handleType,
        meta: { symbol },
      });
    },
    getPointedNodeId: (nodeId, path, depth = -1) => {
      const nodePointers = get().pointersSlice.pointers[nodeId];
      if (depth === 0 || !nodePointers || !nodePointers[path]) return nodeId;
      return get().pointersSlice.getPointedNodeId(
        nodePointers[path].nodeId,
        nodePointers[path].path,
        depth - 1
      );
    },
    clearValues: (nodeId) => {
      get().pointersSlice.value.delete(nodeId);
      get().pointersSlice.decimals.delete(nodeId);
      get().pointersSlice.parameterDetails.delete(nodeId);
    },
    clearAllValues: () => {
      get().pointersSlice.value.clear();
      get().pointersSlice.decimals.clear();
      get().pointersSlice.parameterDetails.clear();
      // get().groupInputs.clear();
      // get().groupOutputs.clear();
    },
  },
});
