/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-promise-executor-return */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-unsafe-optional-chaining */
import type {
  ChainId,
  IWithPlugin,
  RequiredApprovalInterface,
} from '@kiroboio/fct-core';
import {
  BatchMultiSigCall,
  FCTMulticall,
  createApprovalsPlugin,
  isVariable,
} from '@kiroboio/fct-core';
import { useNetwork, useVault, useWallet } from '@kiroboio/fct-sdk';
import { find } from 'lodash';
import { isPluginNode } from '../../Nodes/nodes';
import { useFCTStore } from '../FCTStore';
import { SupportedPlugins } from '../SupportedPlugins';
import { HandleID, isEndNode, isRevertNode } from '../utils';

import type {
  Edge,
  FCTNode,
  PluginInstanceType,
  PluginNodeData,
} from '@kiroboio/fct-builder';
import { MAX_UINT_96 } from '~/lib/utils/number';
import type { PartialFCTOptions } from './useMSCallOptions';
import { useIsGroupMulticallActive } from '~/lib/hooks/useIsGroupMulticallActive';

const createSeparatedFlow = (
  target: string,
  flowName: 'OK' | 'FAIL',
  def: 'STOP' | 'CONT' | 'REVERT'
) => {
  const isEnd = isEndNode(target);
  const isRevert = isRevertNode(target);
  // eslint-disable-next-line no-nested-ternary
  const flow = isEnd ? 'STOP' : isRevert ? 'REVERT' : target ? 'CONT' : def;
  return {
    flow: `${flowName}_${flow}`,
    target: isRevert || isEnd ? undefined : target,
  };
};

export const createFlow = (nodeId: string, edges: Edge[]) => {
  const successTarget = getSuccessTargetId(edges, nodeId) || '';
  const successFlow = createSeparatedFlow(successTarget, 'OK', 'STOP');

  const failTarget = getFailTargetId(edges, nodeId) || '';
  const failFlow = createSeparatedFlow(failTarget, 'FAIL', 'REVERT');

  return {
    flow: `${successFlow.flow}_${failFlow.flow}` as any,
    success: successFlow.target,
    fail: failFlow.target,
  };
};

export const usePrepareFCT = () => {
  const {
    data: {
      raw: { chainId, netId: networkName },
    },
  } = useNetwork();
  const { data: wallet } = useWallet();
  const { data: vault } = useVault();
  const isMulticallTestActive = useIsGroupMulticallActive();
  const createApproveAllErc20Plugin = (
    nodes: FCTNode[],
    sortedNodeIds: string[],
    vaultAddress: string
  ) => {
    if (chainId === 0) return;
    const filteredNodes = nodes.filter(isPluginNode);

    const sortedNodes = sortedNodeIds
      .map((id) => filteredNodes.find((n) => n.id === id))
      .filter(Boolean) as FCTNode<PluginNodeData>[];

    let allRequiredApprovals: RequiredApprovalInterface[] = [];
    const uniqueTokenSenderParams: Set<string> = new Set();
    sortedNodes.forEach((node) => {
      if (!node) return;
      const pluginWithParams = createPlugin(node);
      if (!pluginWithParams) return;
      const pluginRequiredApprovals = pluginWithParams
        .getRequiredApprovals()
        .filter((approve) => {
          if (!approve.from) return true;
          if (typeof approve.from !== 'string') return false;

          return approve.from.toLowerCase() === vaultAddress.toLowerCase();
        })
        .map((approve) => {
          const tokenSenderParam = `${approve.to}${approve.params[0]}`;
          if (uniqueTokenSenderParams.has(tokenSenderParam)) return;
          if (approve.params[1] && isVariable(approve.params[1]))
            approve.params[1] = MAX_UINT_96;

          uniqueTokenSenderParams.add(tokenSenderParam);
          return approve;
        })
        .filter(Boolean) as RequiredApprovalInterface[];
      allRequiredApprovals = [
        ...allRequiredApprovals,
        ...pluginRequiredApprovals,
      ];
    });
    if (!allRequiredApprovals.length) return;
    return createApprovalsPlugin({
      requiredApprovals: allRequiredApprovals,
      chainId: String(chainId) as ChainId,
    });
  };

  const createPlugin = <T extends FCTNode = FCTNode>(
    node: T
  ): PluginInstanceType | undefined => {
    return useFCTStore.getState().getPluginForNode({ id: node.id, node });
  };

  const getPluginsOutputs = async (
    withAutoApprovals?: boolean,
    customNativeVaultAmount?: string,
    customNativeWalletAmount?: string
  ) => {
    useFCTStore.getState().testRunSlice.resetAll();
    await SupportedPlugins.setTestProvider({
      from: vault.raw.address,
      wallet: wallet.raw.address,
      customNativeVaultAmount,
      customNativeWalletAmount,
    });

    const { intentGraph } = useFCTStore.getState();
    if (!intentGraph) return;

    const { provider, signer, walletSigner } = SupportedPlugins.testProvider;
    if (!provider || !signer) {
      SupportedPlugins.revertTestProvider();
      return;
    }

    const simulations = await intentGraph.getTopologicalSortedSimulations({
      testProvider: provider,
      signer,
      walletSigner,
      withAutoApprovals,
    });
    for (const { simulation, nodeId } of simulations) {
      useFCTStore.getState().testRunSlice.setLoading(nodeId);
      const res = await simulation();

      if (res?.status === 'success') {
        useFCTStore.getState().testRunSlice.setSuccess(res.nodeId);
      } else {
        useFCTStore
          .getState()
          .testRunSlice.setSimulationError(
            res?.nodeId || nodeId,
            res?.message || 'error'
          );
      }
    }

    SupportedPlugins.revertTestProvider();
  };

  const createCalls = async () => {
    const { intentGraph } = useFCTStore.getState();
    if (!intentGraph) return;
    const { calls, computedVariables, validationVariables } =
      await intentGraph.createCalls(
        vault.raw.address,
        true,
        isMulticallTestActive
      );

    console.log({ calls, computedVariables, validationVariables });
    return { calls, computedVariables, validationVariables };
  };

  const createSDKCode = async (_msCallOptions: PartialFCTOptions) => {
    const mappedTypes: Record<string, string> = {
      ACTION: 'actions',
      GETTER: 'getters',
    };
    const { intentGraph } = useFCTStore.getState();
    if (!intentGraph) return;
    const { calls } = await intentGraph.createCalls(vault.raw.address, false);
    // return parse(data as Parameters<typeof parse>[0]);
    return {
      chain: {
        id: chainId.toString(),
        name: networkName,
      },
      vault: vault.raw.address,
      wallet: wallet.raw.address,
      blocks: calls.map((c) => {
        return {
          nodeId: c.nodeId,
          method: c.plugin?.name,
          methodType: mappedTypes[c.plugin?.type || ''] || 'getters',
          protocol: c.plugin?.protocol,
          params: c.plugin?.input.paramsList.map(
            ({ key, param: { value } }) => ({ key, value })
          ),
          outputs: c.plugin?.output.paramsList.map(({ key }) => ({ key })),
          options: c.options,
        };
      }),
    }; //  as Parameters<typeof parse>[0];
  };

  const createFCTData = async (
    msCallOptions: PartialFCTOptions,
    { forNotifications = false } = {}
  ) => {
    // const flowPlugins = intentGraph?.getTopologicalSortedPlugins();
    // if (!flowPlugins) return;
    const { intentGraph } = useFCTStore.getState();
    if (!intentGraph) return;
    const { calls, computedVariables, validationVariables } =
      await intentGraph.createCalls(vault.raw.address, false);

    const batch = new BatchMultiSigCall({
      chainId: +chainId as any,
      options: {
        ...msCallOptions,
        validFrom: String(Math.max(Number(msCallOptions.validFrom) - 30, 0)), // block accuracy
        expiresAt: String(Math.max(Number(msCallOptions.expiresAt) + 30, 0)), // block accuracy
        payableGasLimit: undefined,
      },
    });

    batch.version = chainId === 11155111 ? '0x020203' : '0x020201';

    const magic = calls.some(
      (call) => call.plugin?.methodInterface === 'magic'
    );
    calls.forEach((call) => {
      if (call.plugin?.methodInterface === 'magic') {
        call.from = '0xFC00000000000000000000000000000000000001'; // first external variable
        call.plugin.toEns = '@lib:multicall';
      }
    });
    if (magic) {
      batch.setOptions({ maxGasPrice: `0x${(4e9).toString(16)}` });
      for (const call of calls as IWithPlugin[]) {
        call.options = { ...call.options, payerIndex: 0 };
      }
    }

    const res = await batch.createMultiple(calls as IWithPlugin[]).then(() => {
      if (forNotifications)
        return {
          data: batch.exportNotificationFCT(),
          map: batch.exportMap(),
        };

      computedVariables.forEach((compVar) => {
        if (!compVar) return;
        if (typeof compVar === 'string') return;
        if (compVar.type !== 'computed') return;
        batch.addComputed(compVar as any);
      });
      validationVariables.forEach((validationVar) => {
        if (!validationVar) return;
        if (typeof validationVar === 'string') return;

        batch.addValidation(validationVar as any);
      });
      const fct = batch.exportFCT();
      console.log({ fct });

      return {
        data: fct,
        map: batch.exportMap(),
      };
    });
    try {
      // const fctData = await FCTMulticall.compressFCTInMulticall({
      //   FCT: batch,
      //   sender: vault.raw.address,
      // });
      // console.log({ fctData });
      // return {
      //   data: fctData,
      //   map: batch.exportMap(),
      // };
    } catch (e) {
      console.error({ e });
    }
    return res;
  };

  return {
    createFCTData,
    createSDKCode,
    getPluginsOutputs,
    createPlugin,
    createApproveAllErc20Plugin,
    createCalls,
  };
};

function getFailTargetId(edges: Edge[], nodeId: string) {
  return find(edges, { source: nodeId, sourceHandle: HandleID.Fail })?.target;
}

function getSuccessTargetId(edges: Edge[], nodeId: string) {
  return find(edges, { source: nodeId, sourceHandle: HandleID.Success })
    ?.target;
}
